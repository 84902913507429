import styled from 'styled-components';
import { animateUpDown } from '../../assets/css/animations';
import { device } from '../../theme';

export const AboutAreaWrap = styled.section`
  padding-top: ${({ noPadding }) => (noPadding ? '0px' : '100px')};
  padding-bottom: 50px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 20px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 10px;
  }
`;

export const AboutTextBox = styled.div`
  max-width: 570px;
  float: right;
  width: 100%;
  @media ${device.medium} {
    margin: 0 auto 30px;
    float: none;
  }
`;

export const MainImageBox = styled.div`
  position: relative;
  z-index: 8;
  text-align: center;
  max-width: 570px;
  max-height: 350px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  @media ${device.large} {
    max-width: 400px;
    margin-bottom: 100px;
  }
  @media ${device.medium} {
    max-width: 570px;
    margin-bottom: 180px;
  }
  @media ${device.small} {
    max-width: 400px;
    margin-bottom: 100px;
  }
`;

export const VideoBtnWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const AboutImageBox = styled.div`
  position: relative;
  .animation_image {
    .gatsby-image-wrapper {
      animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
      animation-name: ${animateUpDown};
      animation-iteration-count: infinite;
    }
    &.one {
      .gatsby-image-wrapper {
        animation-duration: 3.2s;
      }
    }
    &.two {
      .gatsby-image-wrapper {
        animation-duration: 3.8s;
      }
    }
    &.three {
      .gatsby-image-wrapper {
        animation-duration: 3.4s;
      }
    }
    &.four {
      .gatsby-image-wrapper {
        animation-duration: 3.4s;
      }
    }
  }
  img {
    border-radius: 5px;
  }
`;

export const SectionBottom = styled.div`
  margin-top: 30px;
`;