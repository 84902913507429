import React, { Fragment, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import parse from 'html-react-parser';
import Image from '../Image';
import { Container, Row, Col } from '../ui/Wrapper';
import SectionTitle from '../ui/section-title';
import AccordionWrap from '../ui/accordion';
import VideoButton from '../ui/video-button';
import ModalVideo from '../ui/modal-video';
import Button from '../ui/button';
import {
  AboutAreaWrap,
  AboutTextBox,
  AboutImageBox,
  ImageBox1,
  ImageBox2,
  ImageBox3,
  ImageBox4,
  SectionBottom,
  MainImageBox,
  VideoBtnWrap,
} from './styles';
import useSiteLocation from '../../hooks/useSiteLocation';

const DemonstrationVideo = ({
  sectionTitleStyle,
  buttonOneStyle,
  buttonTwoStyle,
  title,
  subtitle,
  action,
  secondaryAction,
  backgroundImage,
  videoItems,
  noPadding,
}) => {
  const { buildPath } = useSiteLocation();
  const [videoOpen, setVideoOpen] = useState(false);
  const modalVideoOpen = () => {
    setVideoOpen(true);
  };

  const modalVideoClose = () => {
    setVideoOpen(false);
  };

  const [, videoId] = videoItems.videoLink.split('=', -1);
  const [, videoChannel] = videoItems.videoLink.split('.');

  const data = useStaticQuery(graphql`
    query {
      dedicacao: file(relativePath: { eq: "images/preparationCourses/faq/dedicacao.png" }) {
        childImageSharp {
          fluid(maxWidth: 310, maxHeight: 190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
      determinacao: file(relativePath: { eq: "images/preparationCourses/faq/determinacao.png" }) {
        childImageSharp {
          fluid(maxWidth: 188, maxHeight: 115, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
      disciplina: file(relativePath: { eq: "images/preparationCourses/faq/disciplina.png" }) {
        childImageSharp {
          fluid(maxWidth: 188, maxHeight: 115, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
      coragem: file(relativePath: { eq: "images/preparationCourses/faq/coragem.png" }) {
        childImageSharp {
          fluid(maxWidth: 190, maxHeight: 190, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  `);

  const { dedicacao, determinacao, disciplina, coragem } = data;

  return (
    <>
      <AboutAreaWrap noPadding={noPadding}>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle {...sectionTitleStyle} subtitle={subtitle} title={title} />
            </Col>
          </Row>
          <Row alignitems="center" className="justify-content-center">
            <Col lg={12}>
              <AboutImageBox>
                <ImageBox1 className="animation_image one">
                  <Image fluid={dedicacao.childImageSharp.fluid} isAbsolute alt="Dedicação" />
                </ImageBox1>
                <ImageBox2 className="animation_image two">
                  <Image fluid={determinacao.childImageSharp.fluid} isAbsolute alt="Determinação" />
                </ImageBox2>
                <MainImageBox>
                  <Image fluid={backgroundImage} alt="Play" />
                  <VideoBtnWrap>
                    <VideoButton skin="primary" onClick={modalVideoOpen} />
                  </VideoBtnWrap>
                </MainImageBox>
                <ModalVideo channel={videoChannel} videoId={videoId} isOpen={videoOpen} onClose={modalVideoClose} />
                <ImageBox3 className="animation_image three">
                  <Image fluid={disciplina.childImageSharp.fluid} isAbsolute alt="Disciplina" />
                </ImageBox3>
                <ImageBox4 className="animation_image four">
                  <Image fluid={coragem.childImageSharp.fluid} isAbsolute alt="Coragem" />
                </ImageBox4>
              </AboutImageBox>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <SectionBottom className="text-center">
                {action && (
                  <Button as={Link} to={buildPath(action.link)} target="_blank" {...buttonOneStyle}>
                    {action.label}
                  </Button>
                )}
                {secondaryAction && (
                  <Button as={Link} to={secondaryAction.link} target="_blank" {...buttonTwoStyle}>
                    {secondaryAction.label}
                  </Button>
                )}
              </SectionBottom>
            </Col>
          </Row>
        </Container>
      </AboutAreaWrap>
    </>
  );
};

DemonstrationVideo.propTypes = {
  sectionTitleStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  secondaryAction: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  backgroundImage: PropTypes.object.isRequired,
  videoItems: PropTypes.shape({
    videoLink: PropTypes.string,
  }).isRequired,
  noPadding: PropTypes.bool,
  buttonOneStyle: PropTypes.object,
  buttonTwoStyle: PropTypes.object,
};

DemonstrationVideo.defaultProps = {
  subtitle: null,
  action: null,
  secondaryAction: null,
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  noPadding: false,
  buttonOneStyle: {
    m: '10px',
    hover: '2',
  },
  buttonTwoStyle: {
    m: '10px',
    varient: 'outlined',
  },
};

export default DemonstrationVideo;
