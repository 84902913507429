import React from 'react';

import PropTypes from 'prop-types';
import { RequiredMark, StyledLabel } from './styles';

const Label = ({ name, description, noMargin, required, children }) => {
  return (
    <StyledLabel htmlFor={name} noMargin={noMargin}>
      {children} {description ? <small>({description})</small> : null}
      {required ? <RequiredMark /> : null}
    </StyledLabel>
  );
};

Label.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  noMargin: PropTypes.bool,
};

Label.defaultProps = {
  description: null,
  required: false,
  noMargin: false,
};

export default Label;
