import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../Image';
import {
  TeamMemberWrap,
  TeamMemberInner,
  TeamMemberImage,
  TeamMemberInfo,
  TeamMemberName,
  TeamMemberDesignation,
} from './styles';

const TeamMember = ({ image, name, description, ...restProps }) => {
  const { nameStyle, roleStyle, infoStyle, socialStyle, wrapperStyle, ...restStyles } = restProps;
  return (
    <TeamMemberWrap mb="30px" {...restStyles}>
      <TeamMemberInner>
        <TeamMemberImage>
          <Image fluid={image} alt={name || 'Professor'} />
        </TeamMemberImage>
        <TeamMemberInfo textalign="center">
          <TeamMemberName {...nameStyle}>{name}</TeamMemberName>
          {description && <TeamMemberDesignation {...roleStyle} dangerouslySetInnerHTML={{ __html: description }} />}
        </TeamMemberInfo>
      </TeamMemberInner>
    </TeamMemberWrap>
  );
};

TeamMember.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

TeamMember.defaultProps = {
  description: null,
};

export default TeamMember;
