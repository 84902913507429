import styled, { css } from 'styled-components';
import { device } from '../../../theme';

export const TeamMemberInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  @media ${device.small} {
    .gatsby-image-wrapper {
      max-width: 100% !important;
    }
  }
`;

export const TeamMemberImage = styled.div`
  position: relative;
  padding: 12%;
`;

export const TeamMemberInfo = styled.div`
  margin-top: 20px;
  text-align: ${props => props.textalign};
`;

export const TeamMemberName = styled.h5`
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
`;

export const TeamMemberDesignation = styled.p``;

export const TeamMemberWrap = styled.div`
  ${props =>
    props.layout === 1 &&
    css`
      ${TeamMemberInner} {
        max-width: 310px;
      }
    `}
  ${TeamMemberInner} {
    margin-bottom: ${props => props.mb};
    ${props =>
      props.responsive &&
      css`
        ${props =>
          props.responsive.small &&
          css`
            @media ${device.small} {
              margin-bottom: ${props => props.responsive.small.mb};
            }
          `}
      `}
  }
`;
