import styled from 'styled-components';

export const RequiredMark = styled.span`
  &:before {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    content: '*';
  }
`;

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.typography.lg};
  ${({ noMargin }) => (noMargin ? 'margin-bottom: 0;' : '')}
`;
