import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { HeroBtnGroup, HeroTextBox, HeroWrapper, ImageBox, ImageBoxWrap } from './hero.style';
import { Col, Container, Row } from '../ui/Wrapper';
import Heading from '../ui/Heading';
import Button from '../ui/button';
import Text from '../ui/Text';
import Image from '../Image';

const Hero = ({ title, subtitle, action, description, backgroundImage, image, style, dark }) => {
  const { subtitleStyle, titleStyle, btnStyle } = style;

  return (
    <>
      <HeroWrapper fluid={backgroundImage}>
        <Container>
          <Row alignitems="center">
            <Col lg={8} sm={12}>
              <HeroTextBox>
                {subtitle && (
                  <Heading {...subtitleStyle} color={dark ? 'gray' : 'primaryLight'}>
                    {subtitle}
                  </Heading>
                )}
                {title && (
                  <Heading {...titleStyle} color={dark ? 'white' : 'primary'}>
                    {parse(title)}
                  </Heading>
                )}
                {description && <Text color={dark ? 'gray' : 'primaryDark'}>{description}</Text>}
                <HeroBtnGroup>
                  {action && (
                    <Button to={action.link} {...btnStyle}>
                      {action.label}
                    </Button>
                  )}
                </HeroBtnGroup>
              </HeroTextBox>
            </Col>
            <Col md={4} sm={12}>
              <ImageBoxWrap>
                {image && (
                  <ImageBox>
                    <Image fluid={image} alt={title} />
                  </ImageBox>
                )}
              </ImageBoxWrap>
            </Col>
          </Row>
        </Container>
      </HeroWrapper>
    </>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  backgroundImage: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  action: PropTypes.shape({
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  style: PropTypes.shape({
    subtitleStyle: PropTypes.shape({}),
    titleStyle: PropTypes.shape({}),
    btnStyle: PropTypes.shape({}),
  }),
  dark: PropTypes.bool,
};

Hero.defaultProps = {
  action: null,
  description: null,
  dark: false,
  style: {
    subtitleStyle: {
      as: 'h6',
      lineHeight: '36px',
      letterspacing: '3px',
      fontWeight: 700,
      texttransform: 'uppercase',
      mb: '20px',
      responsive: {
        xsmall: {
          fontSize: '16px',
          letterspacing: '1px',
        },
      },
    },
    titleStyle: {
      as: 'h1',
      fontweight: 500,
      fontSize: '68px',
      letterspacing: 0,
      lineHeight: 1.2,
      mb: '15px',
      child: {
        color: 'secondary',
      },
      responsive: {
        xlarge: {
          fontSize: '60px',
        },
        large: {
          fontSize: '46px',
        },
        medium: {
          fontSize: '40px',
        },
        small: {
          fontSize: '34px',
        },
        xsmall: {
          fontSize: '28px',
        },
      },
    },
    btnStyle: {
      skin: 'secondary',
      hover: '2',
      mr: '20px',
      responsive: {
        xsmall: {
          m: '10px',
        },
      },
    },
  },
};

export default Hero;
