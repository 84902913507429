import styled, { css } from 'styled-components';
import { device } from '../../theme';

export const TestimonialWrap = styled.div`
  background: ${props => props.bgColor};
  padding: ${props => props.p};
  border-radius: ${props => props.borderRadius};
  /* transition: ${props => props.theme.transition}; */
  @media ${device.small} {
    padding: 24px;
  }
`;

export const TestimonialSubject = styled.h6`
  ${({ theme, mb, fontSize, color }) => css`
    margin-bottom: ${mb};
    font-size: ${fontSize};
    color: ${color};

    strong {
      color: ${theme.colors.secondary};
    }
  `};
`;

export const TestimonialReview = styled.div`
  ${({ fontSize, color, mt, theme }) => css`
    font-size: ${fontSize};
    color: ${theme.colors[color]};
    ${mt ? `margin-top: ${mt}px;` : ''}
    a {
      color: ${theme.colors.secondary};

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`;

export const AuthorInfoWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: ${props => props.mt};
`;

export const AuthorMedia = styled.div`
  flex-basis: 90px;
  margin-right: ${props => props.mr};
  img {
    border-radius: ${props => props.circle && '50%'};
  }
`;

export const AuthorInfo = styled.div`
  flex-grow: 1;
`;

export const AuthorName = styled.h6`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
`;

export const ResponsibleName = styled.div`
  font-size: 0.9rem;
`;

export const AuthorRole = styled.span`
  display: block;
  font-size: 14px;
  &:before {
    content: ' / ';
    padding: 0 5px;
  }
`;
